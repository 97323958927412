<template>
  <div>
    <header-box :title="`${$t('코드 관리')} > ${$t('건물용도 코드')}`"/>
    
    <div class="col-xl-12">
      <hr class="mt-3 mb-3"/>
      
      <div class="row">
        <search-box :ref="'search'" @search:data="onSearch"/>
      </div>
      
      <div class="bg-white">
        <grid-box :data="items" @reSearch="reSearch"/>
      </div>
    </div>
  </div>
</template>

<script>
// import backEndApi from "../../../../api/backEndApi";
import SearchBox from "./SearchBox.vue";
import HeaderBox from "../../../component/headerBox/sys/Header.vue";
import GridBox from "../../../component/gridBox/sys/codes/build-usage/BuildUsageCodeTable.vue";

export default {
  name: "PointMgmt",
  props: [],
  components: { 
    SearchBox,
    GridBox,
    HeaderBox
  },
  watch: {

  },
  data() {
    return {
      items : null,
    };
  },
  created() {
    console.log("point management pointMgmt");
    
  },
  mounted() {
    
  },
  methods: {
    onSearch(data){
      this.items = data;
    },
    reSearch(){
      this.$refs.search.reSearch();
    },
  }
};
</script>

<style scoped>

</style>
