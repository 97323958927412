<template>
<div>
  
  <div class="row-vh d-flex flex-row">
    <div class="w-50">
      <div class="d-flex flex-row align-items-center mt-3">
        <div class="col-3 text-left d-flex">
          <span class="h5 mb-0">{{$t('용도 코드')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px'}">check_circle</i>
        </div>
        <div class="col-8">
          <input :ref="'buildusageCode'" class="form-control" v-model="model.buildusageCode" disabled="disabled"/>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center mt-3">
        <div class="col-3 text-left">
          <span class="h5">{{$t('사용여부')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px'}">check_circle</i>
        </div>
        <div class="col-8">
          <select class="form-control" :ref="'enabled'" v-model="model.enabled">
            <option value="">{{$t('선택')}}</option>
            <option value="Y">{{$t('사용')}}</option>
            <option value="N">{{$t('사용안함')}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="w-50">
      <div class="d-flex flex-row align-items-center mt-3">
        <div class="col-3 text-left d-flex">
          <span class="h5 mb-0">{{$t('용도 코드명')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px'}">check_circle</i>
        </div>
        <div class="col-8">
          <input :ref="'buildusageCodeName'" class="form-control" v-model="model.buildusageCodeName"/>
        </div>
      </div>
    </div>
  </div>
</div>
    
</template>

<style scoped>
.form-control-none {
    display: block;
    width: 100%;
    height: calc(1.5em + 14px + 2px);
    padding: 7px 0.75rem;
    font-size: .75rem;
    font-weight: 400;
    /* line-height: 1.5; */
    color: #2d353c;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid #d5dbe0;
    border-radius: 4px;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}
</style>

<script>
import backEndApi from "../../../../api/backEndApi";
import AppErrorHandler from "@src/appErrorHandler";

export default {
  components: {
  },
  props: ["buildusageCode"],
  watch: {

  },
  data() {
    return {
      model: {
        buildusageCode: "",
        buildusageCodeName: "",
        enabled: "",
      },

      // define the default value
      applyZoneIdx: null,
      // define options
      options: null,
    };
  },
  computed: {

  },
  async created() {
    await this.setModel();
  },
  methods: {
    async setModel() {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.codes.getBuildUsageCode(this.buildusageCode);
        const data = result.data;

        this.model = {
          buildusageCode: data.buildusageCode,
          buildusageCodeName: data.buildusageCodeName,
          enabled: data.enabled
        };
      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }
    },
  }
};
</script>
